@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cabinet+Grotesk:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}

body {
  font-family: 'Montserrat', sans-serif !important;
    -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
}


.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.videoBackground {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; 
}

.headerContainer {
  position: relative;
  z-index: 1; 
 
}

.downArrowButton{
  animation: transform 0.5s  .5s infinite alternate;
}

@keyframes transform {
  from{
    transform:translatey(-1.5rem);
  }
}

.contact {
  font-family: 'Open Sans', sans-serif !important;
}
@media only screen and (max-width: 600px) {
  #typed-text {
    margin-top: 80px; /* Adjust the value as needed */
  }
}