body {
  overflow-x: hidden; /* Add this to prevent horizontal scroll */
}

.home {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.headerContainer {
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
}

/* Additional styles for the video background */
.video-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.home .headerContainer h1 {
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 70px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: rgb(236, 229, 229);
  text-align: center;
  text-shadow: -5px 9px 1px rgba(28, 38, 20, 10.5);
}

.home .headerContainer p {
  margin-top: 15px;
  padding: 0;
  font-size: 24px;
  font-weight: lighter;
  color: rgb(252, 248, 248);
  text-align: center;
  /* Add text shadow */
}

.headerContainer button {
  background-color: #121619;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  width: 180px;
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.headerContainer button:hover {
  background-color: #07090a;
  cursor: pointer;
  transition: 0.3s ease-in;
}

/* mobile view */
@media only screen and (max-width: 600px) {
  .home .headerContainer {
    max-width: 90%;
  }

  .home .headerContainer h1 {
    font-size: 40px;
    height: 30px;
    margin: 30px 0px;
    color: white;
    text-shadow: -5px 9px 1px rgba(28, 38, 20, 10.5);
  }

  .home .headerContainer p {
    font-size: 20px;
    color: white;
    margin-top: 40px;
    text-shadow: -5px 9px 1px rgba(28, 38, 20, 10.5);
  }

  .headerContainer button {
    background-color: white;
    color: #121619;
    margin-bottom: 30px;
  }
}
