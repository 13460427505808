.navigation-menu {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 40px; /* Adjust the values as needed */
}

.navigation-menu li {
  margin: 0px 20px;
}

.navigation-menu li a {
  text-decoration: none;
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease; /* Add transition for smooth effect */
}

.navigation-menu li a:hover {
  transform: scale(1.1); /* Apply zoom effect on hover */
}

.active {
  color: rgb(250, 103, 6) !important;
  font-weight: bold !important;
}


/* mobile view */
.mobile-navigation {
  list-style: none;
}

.mobile-navigation li {
  margin-top: 20px;
}

.mobile-navigation li a {
  text-decoration: none;
  font-size: 1.4rem;
  color: rgb(241, 236, 236);
  transition: transform 0.3s ease; /* Add transition for smooth effect */
}

.mobile-navigation li a:hover {
  transform: scale(1.2); /* Apply zoom effect on hover */
}
